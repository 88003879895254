import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { useBlogAuthors } from '@helpers-blog'

const PageAuthors = props => {
  const authors = useBlogAuthors()

  return (
    <Layout {...props}>
      <Seo title='Our Team' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='About Us'
          subheader='The WI is a unique organisation shaped by its members. In 1915, we set out to give women a voice and to be a force for good in the community.  Since then, our members and our ambitions alike have grown tremendously.  Today, we are the largest women’s organisation in the UK and we pride ourselves on being a trusted place for all women of all generations, to share experiences and learn from each other. A WI membership offers the opportunity to meet women in your local area in-person and virtually, to make friends and make a difference in your community. We campaign nationally on a wide range of issues and provide life-long learning and self-development opportunities for women in England and Wales.'
        />
      </Stack>
      <Stack>
        <Main>
          {authors.map((author, i) => (
            <React.Fragment key={`item-${i}`}>
              <Divider />
              <AuthorExpanded author={author} withLink />
            </React.Fragment>
          ))}
        </Main>
      </Stack>
    </Layout>
  )
}

export default PageAuthors
